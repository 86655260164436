import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ApiService } from "@core/api/api.service";
import { UsersService } from "@core/api/users.service";
import { CAuthService } from "@core/services/auth.service";
import { CheckForUpdateService } from "@core/services/check-for-update-service";
import { ProctorService } from "@core/services/proctor.service";
import { UserService } from "@core/services/user.service";

import { Subject, interval, of, switchMap, takeUntil } from "rxjs";

@Component({
  selector: "ngx-app",
  templateUrl: "./app.component.html",
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private usersService: UsersService,
    private router: Router,
    private proctorService: ProctorService,
    private userService: UserService,
    private updateService: CheckForUpdateService,
    private auth: CAuthService,
  ) {
    this.updateService.init();
  }

  defaultStepOptions: {
    classes: "tutorial";
    scrollTo: { behavior: "smooth"; block: "center" };
    showCancelLink: true;
  };

  unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.pollData();
  }

  private pollData() {
    if (this.auth.isAuthenticated()) {
      this.usersService.pingUser().subscribe();
    }

    interval(1000 * 60)
      .pipe(
        switchMap(() => {
          if (this.auth.isAuthenticated()) {
            return this.usersService.pingUser();
          }
          return of(false);
        }),
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  checkExitProctor() {
    if (this.proctorService.info.proctorModeActive()) {
      this.proctorService.exitProctorMode();
    }
  }

  navigate(path, requiredPermission = null) {
    const callback = () => {
      this.checkExitProctor();
      this.router.navigate(path);
    };

    if (!requiredPermission) {
      callback();
      return;
    }

    this.userService.me().subscribe((me) => {
      if (!this.userService.checkUserHasPermission(me, requiredPermission)) {
        return;
      }
      callback();
    });
  }

  private stopPing() {
    this.unsubscribe$.next(true);
  }

  @HostListener("window:focus")
  onFocus() {
    this.stopPing();
    this.pollData();
  }

  @HostListener("window:blur")
  onBlur() {
    this.stopPing();
  }

  // Global hotkeys
  @HostListener("window:keydown", ["$event"])
  handleKeyUp(event: KeyboardEvent) {
    const shift = event.shiftKey;
    const ctrl = event.ctrlKey;
    const alt = event.altKey;

    // Only react to alt hotkeys
    if (!alt) {
      return;
    }

    if (shift || ctrl) {
      return;
    }

    switch (event.code) {
      case "KeyA":
        this.navigate(["admin"], "admin");
        break;
      case "KeyQ":
        this.checkExitProctor();
        break;
      case "KeyM":
        this.navigate(["moderation"], "moderator");
        break;
    }
  }
}
